@import "aviko";

.autocomplete-holder {
    position: relative;
}
.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
  }
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
  }
  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }


.header {
    @media (min-width: 1024px) {    
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.footer {
    margin-top: 0;
}
.nota {
  text-align: justify;
  line-height: 1.4;
  font-size: 0.8em;
  opacity: 0.6;
  ol {
      list-style-type: decimal;
      margin-left: 1em;
      li {
          padding-left: 1em;
      }
  }
}
.lot {
    position: absolute;
    display: block;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    & + input {
        padding-left: 36px;
    }
}
.banner {
    position: relative;
    @extend .mb-40;
    display: block;
    height: 35vw;
    max-height: 60vh;
    @media (orientation: portrait) {
        height: 35vw;
    }
    overflow: hidden;
    margin: 0;
    padding: 0;
    img {
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto; 
        max-height: none;
        position: absolute;
        top: 0;
        transform: translate3d(0px, -33px, 0px) scale(1.3);
        will-change: transform;
    }
    
}
.main.form .container-fluid,
.main.send .container-fluid {
    @media (orientation: portrait) {
    form {
        h2 {
            margin-top: 1.5em;
            margin-bottom: 0 !important;
        }
    } 
}
@media (orientation: landscape) and (min-width: 1024px) {
    display: flex;
    .banner {
        height: calc(100vh - 30px);
        max-height: none;
        flex: unset;
        width: 100%;
        max-width: 100%;   
        flex: 0 0 45%;
        width: 45%;
        max-width: 45%;
        img {
            min-height: 100%;
            max-width: none;
            width: auto;
            left: -20%;
        }
    }
    .simpleParallax {
        flex: 0 0 45%;
        width: 45%;
        max-width: 45%;
        height: calc(100vh - 30px);
        position: sticky;
        top: 15px;
        .banner {
            flex: unset;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: none;
            img {
                min-height: 100%;
                max-width: none;
                width: auto;
                left: -20%;
            }
        }
        
    }
    & > .row {
        flex: 0 0 55%;
        width: 55%;
        max-width: 55%;
    }
}
    form {
        .line {
            margin: 1em 0 2em;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
        }
        .help {
            text-align: justify;
            line-height: 1.4;
            font-size: 0.85em;
            margin-top: 2em;
            margin-bottom: 2em;
        }
        .total-size {
            line-height: 1.4;
            font-size: 0.85em;
            margin-bottom: 1.5em;
        }
    }
    
        
}

ul.errors {
    display: block;
    background: white;
    list-style: none;
    margin: 0;
    padding: 0 0 0 0;
    li {
        color: #af0000;
        font-weight: 400;
        font-size: 0.8em;
        margin: 0;
        padding: 0 0 0 0.25em;
        line-height: 1.5em;
        &:last-child {
            margin-bottom: 1.5em;
        }
        strong {
            font-weight: 600;
            &:after,
            &:before {
                display: inline-block;
                padding: 0 3px;
                content: '"';
                color: #111;
            }
            &:only-child {
                &:after,
                &:before {
                    content: unset;
                }

            }
        }
    }
}

.langs {
    font-family: 'Open Sans Condensed', sans-serif;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}
.lang-item {
    flex: 1 1 25%;
    margin: 0 1em 1em 0;
}
@media (orientation: portrait) {
    .lang-item {
        flex: 0 0 50%;
        margin: 0 0 1em 0;
    }
}
.lang-link {
    display: flex;
    align-items: center;
}
.lang-flag-wrapper {
    position: relative;
    display: block;
    flex: 0 0 1.5em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.75em;
    overflow: hidden;
    box-shadow: 0 1px 5px 2px rgba(0,0,0,0.2);
    margin-right: 1em;
}
.lang-flag {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 3em;
    min-height: 3em;
}
input[type=file] {
    display: inline-block;
    position: relative;
    margin-top: 1em;
    padding: 0.35em 0 0.5em;
    transition: all 225ms ease;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
input[type=file] {
    height: 50px;
    padding: 10px;
}
input[type=file] {
    + input[type=file] {
        margin-top: 10px;
    }
}
input[type="file"]::-ms-browse {
    margin: 0 5px;
  }
}
::-webkit-file-upload-button {
    visibility: hidden;
}
input[type=file]::before {
    font-family: 'Font Awesome 5 Free';
    content: '\f093';
    font-weight: bold;
    height: 36px;
    line-height: 1em;
    text-align: center;
    border-right: 1px solid #ddd;
    display: inline-flex;
    top: 0;
    left: 0;
    padding: 0.35em 2em;
    position: absolute;
    align-items: center;
    justify-items: center;    
    transition: all 225ms ease;
  }
input[type=file]:hover {
    border-color:  #333;
    &:before {
        background-color:  #333;
        color: #fff;
    }
  }
  .d-block {
      display: block;
  }

  #imageFiles {
      margin: 0;
      &:hover {
          .fileInput {
              opacity: 0.75;

              &:hover {
                  opacity: 1;
                  .imageFileAction {
                    opacity: 0.75;
                    &:hover {
                        opacity: 1;
                        cursor: pointer;
                    }
                  }
              }
          }
      }
  }
  .pristine-error { 
    padding: 5px 0;
    background: #fff;
  }
  .fileInput {
      transition: all 225ms linear;
      .imageFileAction {
          font-size: 0.85em;
          opacity: 0;
          transition: all 225ms linear;
          color: $blue;
      }
      &:nth-child(2n + 2) {
          background-color: lighten(#eee, 2.5%);
      }
    }
  .imageFile {
      font-size: 1em; 
      
      margin: 0;
      display: flex;
      border: 1px solid #ccc;
      border-top-width: 0;
      figure {
        flex: 0 0 115px;
        width: 115px;
        max-width: 115px;
        padding: 0;
        margin: 0 1em 0 0;
        min-height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ccc;
        img {
            display: block;
            max-width: 115px;
        }
    }
    .imageName {
        font-size: 1em;
        line-height: 1;
        margin-top: 1em;
    }
    .imageSize {
        font-size: 1em;
        line-height: 1;
    }
    label {
        display: flex;
        align-items: center;
        margin: 0 !important;
        .fas {
            font-size: 0.85em;
            margin-right:  0.85em;
            opacity: 0.5;
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }
    }
    input {
          display: inline;
          position: static;
      }
      .fileDetails {
        flex-grow: 1;
         display: flex;
         align-items: center;
      }
      .fileName {
          flex: 0 0 70%;
      }
      & + input[type=file] {
          display: none;
      }
  }
  .imageFilePreview {
      border: 1px solid #ccc;
      display: block;
      margin: 15px;
      background: #ccc;
  }
  .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
          width: 28px;
          height: 40px;
          &-minus {
              background-color: darken($light-blue, 10%);
          }
      }
    }
    @keyframes loading {
        0% { opacity: 0.5; }
        100% { opacity: 0.7; }
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .loading {
    position: relative;
      animation: loading 1s linear infinite alternate;
      pointer-events: none;
      button[type=submit] {
        &:before {
            content: '';
            display: block;
                border: 3px solid $blue;
                border-top: 3px solid white;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                animation: spin 2s linear infinite;
                z-index: 100;
                margin-right: 1em;
            }
        }
    }

    @import "./_filepond";