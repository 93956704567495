
 html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

a {
  background: transparent;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
  text-transform: none;
}

select {
  text-transform: none;
}

button, html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input {
  &[type="reset"], &[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  line-height: normal;

  &[type="checkbox"], &[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  &[type="number"] {
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      height: auto;
    }
  }

  &[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;

    &::-webkit-search-cancel-button, &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

:target, input:focus, textarea:focus, select:focus {
  outline: 0;
}

*:active {
  outline: 0 !important;
}

@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  box-sizing: inherit;

  &::before, &::after {
    box-sizing: inherit;
  }
}
