$blue: #1e71b8;
$light-blue: #419ce9;

@import "aviko/reset-min";
@import "aviko/grid";
//@import "aviko/animation";
@import "aviko/btn";
@import "aviko/form";
@import "aviko/style_page";
// @import "aviko/slick";
// @import "aviko/menu";
// @import "aviko/plugins";
@import "aviko/helper";
@import "aviko/style";





