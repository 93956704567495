.form-field {
  h2 {
    margin-top: 1rem;
  }
  label {
    display: block;
  }
  label:not(.error) {
    line-height: normal;
    margin: 0 3px 5px 0;
    font-weight: 700;
    color: #081f6e;
    font-size: 15px;
  }

  input,
  textarea,
  select {
    height: 36px;
    line-height: 36px;
    background-color: #fff;
    border: 1px solid #ccc;
    display: block;
    padding: 0 15px;
    width: 100%;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s ease-in-out;
  }

  .postcode input,
  input[type="number"] {
    padding-right: 0;
  }

  select option {
    padding: 5px;
  }

  textarea {
    line-height: normal;
    padding: 1em 15px;
    height: 3em;
    resize: none;
    overflow: hidden;
  }

  input:not([disabled]):focus,
  textarea:not([disabled]):focus,
  select:focus {
    border-color: #797979;
    outline: 0;
  }
  input[required]:not(.no-ico),
  textarea[required]:not(.no-ico) {
    background-image: url("/static/img/req-ico.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 5px;
  }
.postcode {
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) 5px;
  input {
    background: none !important;
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-position: 0 !important;
  }
  
}
  select[required] {
    background-image: url("/static/img/req-ico.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 5px;
  }

  [type="checkbox"] {
    + label {
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      color: #081f6e;
      position: relative;
      padding: 6px 0 0 40px;
      cursor: pointer;
    }

    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;
    }

    &:not(:checked) + label:before,
    &:checked + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 1px;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #ccc;
    }

    &.default {
      &:not(:checked) + label:before,
      &:checked + label:before {
        border: 1px solid #ccc;
      }
    }

    &:not(:checked) + label:after,
    &:checked + label:after {
      content: "";
      position: absolute;
      top: 8px;
      left: 7px;
      width: 16px;
      height: 16px;
      background: #ecb631;
      transition: all 0.15s;
    }

    &.default {
      &:not(:checked) + label:after,
      &:checked + label:after {
        background: $blue;
      }
    }

    &:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }

    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }

    &:disabled {
      &:not(:checked) + label:before,
      &:checked + label:before {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }

    &:checked:focus + label:before,
    &:not(:checked):focus + label:before {
      border-color: #c39628;
    }

    &.default {
      &:checked:focus + label:before,
      &:not(:checked):focus + label:before {
        border-color: #797979;
      }
    }
  }
}

input.error:not([disabled]),
textarea.error:not([disabled]),
select.error {
  border-color: #b30c0c;
}

label {
  &.error {
    display: none;
    color: #b30c0c;
    font-size: 15px;
    line-height: normal;
    font-weight: normal;
    position: relative;
    bottom: -2px;
  }

  &[generated="true"] {
    display: inline-block;
  }
}

.hidden-submit {
  left: -10000px;
  opacity: 0;
  position: absolute;
  top: -10000px;
}

input {
  &[readonly="readonly"],
  &[readonly],
  &[disabled="disabled"],
  &[disabled] {
    background-color: #f6f6f6 !important;
    color: #989593 !important;
    border-color: #bcbec0 !important;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-input-placeholder {
  color: #c2c2c2;
}

:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: #878787;
}
::-ms-clear {
  display: none;
}
.postcode {
  display: inline-flex;
  align-items: normal;
  justify-content: normal;
  border: 1px solid #ccc;
  background-image: url("/static/img/req-ico.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) 5px;

  input {
    border: 0;
    background-image: none;
    height: 34px;
    &:first-child {
      width: 36px;
    }
    &:last-child {
      width: 72px;
    }
  }
  span {
    align-self: normal;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 8px;
  }
}
.form-field.error {
  .pristine-error.text {
    color: #b30c0c;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 1.5em;
  }
  label {
    color: desaturate(#b30c0c, 50%);
  }
  .postcode,
  select,
  input {
    border-color: #b30c0c;
  }
  .postcode {
    color: #b30c0c;
  }
}
.form-field.success {
  label {
    color: desaturate(#56a90c, 50%);
  }
  input, select, textarea {
    border-color: #56a90c;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 5px;
    background-image: url("/static/img/check-ico.png") !important;
  }
  .postcode {
    border-color: #56a90c;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 5px;
    background-image: url("/static/img/check-ico.png") !important;
    input {
      background: none !important;
    }
  }
}

.hidden {
  display: none;
}

select option[value=""]:first-child {
  color: #999;
}

input[name=postcode] {
  width: auto;
}
.filepond--file-status {
  
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-0.15vw, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(0.15vw, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-0.25vw, 0, 0);
  }

  40%, 60% {
    transform: translate3d(0.25vw, 0, 0);
  }
}
.shake {
  animation: shake 490ms ease-in-out alternate;
}