html {
  body {
    font-family: 'Rajdhani', 'Open Sans Condensed', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    strong {
      font-weight: 700;
    }
  }

    &[lang="ru"],
    &[lang="bg"] {
      body {
        font-family: 'Open Sans Condensed', sans-serif;
        font-weight: 300;
      }
    }
  }
  h1, h2, h3, h4, h5 {
    margin-bottom: 20px;
    font-weight: normal;
    color: #081f6e;
    font-family: 'Refuse Pro', sans-serif;
  }
  
  h1, .h1 {
    font-size: 50px;
    line-height: 44px;
  }
  
  h2, .h2 {
    font-size: 36px;
    line-height: 36px;
  }
  
  h3, .h3 {
    font-size: 24px;
    line-height: 24px;
  }
  
  h4, .h4 {
    font-size: 22px;
    line-height: 22px;
  }
  
  h5, .h5 {
    font-size: 20px;
    line-height: 20px;
  }
  
  h1 a, h2 a, h3 a, h4 a, h5 a {
    color: #081f6e;
    text-decoration: none;
  }
  
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover {
    color: $blue;
  }
  
  a:hover {
    h1, h2, h3, h4, h5 {
      color: $blue;
    }
  }
  
  .html-editor p {
    margin-bottom: 20px;
  
    span[style*="font-size"] {
      line-height: normal;
    }
  }
  
  strong, b {
    font-weight: 700;
  }
  
  a {
    text-decoration: none;
  }
  
  .html-editor {
    p a, label a, td a:not(.btn), li a {
      color: $blue;
      text-decoration: none;
    }
  
    p a:hover, label a:hover, td a:not(.btn):hover, li a:hover {
      color: $light-blue;
    }
  }
  
  img {
    max-width: 100%;
    vertical-align: top;
    max-height: 100%;
  }
  
  hr {
    border: none;
    border-top: 1px solid #ccc;
    margin-bottom: 20px;
    clear: both;
  }
  
  .html-editor {
    ul ul {
      margin-bottom: 10px;
    }
  
    li {
      margin-bottom: 5px;
    }
  
    ul {
      padding-left: 30px;
      list-style-type: disc;
      margin-bottom: 20px;
    }
  
    ol {
      padding-left: 30px;
      list-style-type: decimal;
      margin-bottom: 20px;
  
      ol {
        list-style-type: lower-alpha;
        padding-left: 25px;
        margin-bottom: 10px;
  
        li:first-child {
          margin-top: 10px;
        }
      }
    }
  
    ul ul li:first-child {
      margin-top: 10px;
    }
  
    ol li:last-child, ul li:last-child {
      margin: 0;
    }
  
    .obrazek-z-prawej {
      float: right;
      margin: 0 0 30px 30px;
    }
  
    .obrazek-z-lewej {
      float: left;
      margin: 0 30px 30px 0;
    }
  }
  
  .cke_editable .no-edit {
    display: none;
  }
  
  table {
    width: 100%;
    overflow: hidden;
  
    td, th {
      vertical-align: top;
      padding: 5px 2px;
      line-height: normal;
    }
  }
  
  blockquote {
    border-left: 5px solid $blue;
    padding: 0 0 0 40px;
    font-style: italic;
    margin-bottom: 30px;
  
    p:last-child {
      margin-bottom: 0;
    }
  }
  
  @media all and (max-width: 1370px) {
    h1, .h1 {
      font-size: 46px;
      line-height: 46px;
    }
  
    h2, .h2 {
      font-size: 32px;
      line-height: 32px;
    }
  }
  
  @media all and (max-width: 991px) {
    body {
      font-size: 16px;
      line-height: 26px;
    }
  
    h1, .h1 {
      font-size: 40px;
      line-height: 40px;
    }
  
    h2, .h2 {
      font-size: 28px;
      line-height: 28px;
    }
  
    blockquote {
      border-left-width: 3px;
      padding-left: 20px;
    }
  }
  
  @media all and (max-width: 479px) {}