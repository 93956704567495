

.btn {
    background-color: $blue;
    border: 1px solid transparent;
    text-align: center;
    color: #fff;
    font-size: 18px;
    display: inline-flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    font-family: 'Lobster', cursive;
    font-weight: normal;
  
    &:not(.not-hover):hover, &.active, &:focus {
      background-color: $light-blue;
    }
  }
  
  a:hover .btn {
    background-color: $light-blue;
  }
  
  .btn.btn-primary {
    background-color: #ecb631;
  
    &:not(.not-hover):hover, &.active, &:focus {
      background-color: #fcc333;
    }
  }
  
  a:hover .btn.btn-primary {
    background-color: #fcc333;
  }
  
  .btn {
    &.small {
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      padding: 0 10px;
    }
  
    &[disabled],
    &.btn-disabled {
      color: #b6b5b5 !important;
      font-weight: normal !important;
      text-shadow: 0 1px 1px rgba(255, 255, 255, 0.4) !important;
      border-color: #dadada !important;
      background-color: #e8e8e8 !important;
      cursor: default !important;
  
      i {
        color: #b6b5b5 !important;
      }
    }
  }
  
  @media all and (max-width: 1300px) {}
  
  @media all and (max-width: 768px) {
    .btn {
      padding: 0 15px;
    }
  }