
@font-face {
  font-family: 'Refuse Pro';
  src: url('../fonts/subset-RefusePro.woff2') format("woff2"), url('../fonts/subset-RefusePro.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}


#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    background-attachment: fixed;
    background-size: cover;
    z-index: 9999999999;
    line-height: 100vh;
    text-align: center;
  }
  
  .preloader-in, .preloader-in2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.3);
    z-index: 15000;
    text-align: center;
    color: #fff;
  }
  
  .preloader-in .spinner {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    line-height: 100vh;
    pointer-events: none;
  }
  
  .preloader-in2 .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    pointer-events: none;
  }
  
  html {
    background-color: #fff;
  }
  
  body {
    background-color: #fff;
    overflow-x: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  main {
    flex-grow: 1;
  }
  
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .full-height {
    min-height: calc(100vh - 80px);
  }
  
  .anchor {
    position: absolute;
    z-index: -1;
    top: -20px;
    display: block;
  }
  
  .header {
    > .container-big {
      position: relative;
    }
  
    .i-in2 {
      position: initial;
      height: 140px;
    }
  }
  
  .bx-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #333;
  
    .i-btn {
      min-width: 36px;
      height: 36px;
      line-height: 36px;
      padding: 0;
      border: 1px solid #fff;
  
      .fa-times {
        font-size: 26px;
        line-height: 35px;
      }
    }
  
    input {
      height: 36px;
      line-height: 36px;
      background: #eee;
      padding: 0 30px 0 10px;
      border: 1px solid #fff;
      font-weight: 500;
      width: 100%;
      transition: border-color 0.3s ease-in-out;
  
      &:focus {
        border-color: #797979;
      }
    }
  
    .i-in1 {
      width: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      opacity: 0;
      position: relative;
      margin-right: 10px;
    }
  
    &.active .i-in1 {
      width: 300px;
      opacity: 1;
    }
  
    .i-btn-search {
      border: none;
      background: none;
      color: #333;
      width: 36px;
      height: 36px;
      line-height: 36px;
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;
      display: none;
  
      &:hover, &:focus {
        color: $light-blue;
      }
    }
  
    &.active .i-btn-search {
      display: block;
    }
  }
  
  .section-slider {
    z-index: 1;
    position: relative;
    text-align: center;
    margin: 0;
  
    img {
      width: 100%;
    }
  }
  
  .bx-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #333;
  
    .i-img img {
      width: 100%;
    }
  
    .i-desc {
      flex-grow: 1;
      margin-bottom: 20px;
      font-weight: 600;
    }
  }
  
  .hr {
    background: url(//aviko.pl/system/themes/aviko/img/hr-bg.png) repeat-x;
    height: 15px;
    width: 100%;
  }
  
  .bx-products-filer {
    .item1 {
      border-bottom: 1px solid #081f6e;
      margin-bottom: 30px;
  
      .i-title {
        display: inline-block;
        margin-bottom: 0;
        padding-bottom: 20px;
        position: relative;
  
        &.active {
          a {
            color: $blue;
          }
  
          &::after {
            content: '';
            height: 7px;
            width: 40px;
            position: absolute;
            z-index: 9;
            left: 50%;
            bottom: -4px;
            margin-left: -20px;
            background: #fff;
          }
  
          &::before {
            content: '';
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #081f6e;
            position: absolute;
            z-index: 10;
            left: 50%;
            bottom: -4px;
            margin-left: -4px;
          }
        }
      }
    }
  
    .item2 {
      width: 100%;
      display: flex;
      flex-direction: column;
  
      &.active .i-title {
        color: $blue;
      }
  
      .i-img {
        margin-bottom: 20px;
  
        img {
          width: 100%;
        }
      }
  
      .i-desc {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
  
        .i-btn {
          padding: 0;
          font-size: 26px;
          line-height: 35px;
          min-width: 36px;
          position: relative;
          bottom: -6px;
          margin-left: 10px;
        }
      }
  
      &.active .i-desc .i-btn i {
        transform: rotate(45deg);
      }
    }
  }
  
  .bx-products-filter-checkbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border: 1px solid #081f6e;
  
    .form-field {
      padding: 10px 20px;
    }
  }
  
  .bx-product {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    border: 1px solid transparent;
    padding: 40px 20px;
  
    &:hover {
      border-color: #ecb631;
    }
  
    .i-img {
      background-position: center center;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      position: relative;
      height: 300px;
      line-height: 300px;
  
      &::after {
        content: '';
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #fff;
      }
  
      img {
        transition: opacity 0.3s ease-in-out;
        position: relative;
        z-index: 11;
        vertical-align: middle;
      }
    }
  
    &:hover .i-img {
      &::after {
        display: none;
      }
  
      img {
        opacity: 0;
      }
    }
  
    .i-desc {
      flex-grow: 1;
    }
  }
  
  .bx-product-details .i-main-photo {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  
    &::after {
      content: '';
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #fff;
    }
  
    img {
      transition: opacity 0.3s ease-in-out;
      position: relative;
      z-index: 11;
      vertical-align: middle;
    }
  
    &:hover {
      &::after {
        display: none;
      }
  
      img {
        opacity: 0;
      }
    }
  }
  
  .bx-inspirations-filter {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
  
    .item {
      flex: 0 0 16.666665%;
      max-width: 16.666665%;
      position: relative;
  
      .i-img {
        transition: transform 0.2s ease-in-out;
        padding: 10px;
      }
  
      &:hover .i-img, &.active .i-img {
        transform: scale(1.05);
      }
  
      .i-img img {
        width: 100%;
      }
  
      &.active {
        &::before {
          content: '';
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #081f6e;
          position: absolute;
          z-index: 10;
          left: 50%;
          bottom: -25px;
          margin-left: -3px;
        }
  
        &::after {
          content: '';
          height: 7px;
          width: 40px;
          position: absolute;
          z-index: 9;
          left: 50%;
          bottom: -25px;
          margin-left: -20px;
          background: #fff;
        }
      }
    }
  
    .item-desc {
      border: 1px solid #081f6e;
      padding: 30px 60px 10px 60px;
      width: 100%;
      margin: 20px 10px;
  
      .i-btn {
        min-width: 36px;
        font-size: 26px;
        line-height: 35px;
        padding: 0;
  
        i {
          transform: rotate(45deg);
        }
      }
    }
  }
  
  .bx-blog {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 18px;
    line-height: 24px;
    color: #333;
  
    .i-more {
      color: #081f6e;
    }
  
    &:hover .i-more {
      color: $blue;
    }
  }
  
  .bx-recipe {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #081f6e;
    margin-bottom: 20px;
    position: relative;
  
    .i-more {
      color: #081f6e;
    }
  
    &:hover .i-more {
      color: $blue;
    }
  
    .i-desc {
      flex-grow: 1;
    }
  
    .i-ico-group {
      position: absolute;
      z-index: 10;
      right: 10px;
      top: 10px;
      width: 15%;
      max-width: 99px;
  
      img {
        width: 100%;
        opacity: 1 !important;
      }
    }
  }
  
  .arrow-see-inspirations {
    background: url(//aviko.pl/system/themes/aviko/img/arrow-see-inspirations.png) left top no-repeat;
    width: 225px;
    height: 259px;
    margin: -50px auto;
    font-family: 'Lobster', cursive;
    font-weight: normal;
    display: flex;
    align-items: center;
    color: $blue;
    font-size: 18px;
    line-height: normal;
    padding-left: 80px;
    text-align: left;
    pointer-events: none;
  }
  
  .bx-recipe-details .i-main-photo {
    position: relative;
  
    .i-ico-group {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20%;
      max-width: 138px;
  
      img {
        width: 100%;
        opacity: 1 !important;
      }
    }
  }
  
  .bx-pager {
    padding: 20px 5px;
    text-align: center;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 700;
    color: #ccc;
  
    a {
      color: #081f6e;
      margin: 0 10px;
  
      &:hover, &:focus {
        color: $light-blue;
      }
  
      &.i-prev {
        margin-right: 20px;
      }
  
      &.i-next {
        margin-left: 20px;
      }
  
      &.active {
        color: #ccc;
      }
    }
  
    i {
      font-size: 35px;
    }
  }
  
  .bx-accordion {
    margin-bottom: 20px;
  
    .i-accordion-opener {
      font-size: 24px;
      line-height: normal;
      font-weight: 700;
      color: #081f6e;
      border-bottom: 1px solid #ccc;
      display: flex;
      width: 100%;
      justify-content: space-between;
  
      &:hover, &:focus, &[aria-expanded='true'] {
        color: $blue;
      }
  
      i {
        font-size: 36px;
        margin-left: 10px;
        transition: all 0.2s ease-in-out;
      }
  
      &[aria-expanded='true'] i {
        transform: rotate(180deg);
      }
    }
  
    .i-accordion-desc {
      &:not(.show) {
        height: 0;
        transition: height 0.3s ease-in-out;
      }
  
      height: 100%;
      overflow: hidden;
    }
  
    .bx-accordion-in {
      padding: 20px 0 0 20px;
  
      &:last-child {
        margin-bottom: 10px;
      }
  
      .i-accordion-opener {
        font-size: 24px;
  
        i {
          font-size: 30px;
        }
      }
    }
  }
  
  .bx-download {
    border: 1px solid #081f6e;
    padding: 10px 10px 10px 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  
    .i-ico {
      font-size: 40px;
      position: absolute;
      left: 0;
      color: #081f6e;
      width: 60px;
      text-align: center;
    }
  
    .i-btn {
      height: 40px;
      line-height: 40px;
      padding: 0 30px;
    }
  }
  
  .bx-icons a {
    width: 36px;
    height: 36px;
    padding: 0;
    line-height: 36px;
    font-size: 22px;
  }
  
  .ico-go-top {
    width: 45px;
    height: 45px;
    border: 2px solid $blue;
    border-radius: 50%;
    position: fixed;
    z-index: 88;
    right: 10px;
    bottom: 20px;
    display: none;
    cursor: pointer;
  
    &::after {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      border-top: 2px solid $blue;
      border-right: 2px solid $blue;
      transform: rotate(-45deg);
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -5px 0 0 -8px;
    }
  
    &:hover {
      opacity: 0.8;
    }
  }
  
  .footer {
    margin-top: 70px;
  
    .i-in1 {
      border-top: 1px solid #eee;
      padding: 30px 0;
    }
  
    .i-logo img {
      max-height: 65px;
    }
  
    .i-menu {
      font-family: 'Refuse Pro', sans-serif;
      font-size: 24px;
      font-weight: normal;
  
      li {
        display: inline-block;
        margin-right: 20px;
  
        a {
          color: #081f6e;
  
          &:hover, &:focus, &.active {
            color: $blue;
          }
        }
      }
    }
  
    .i-social {
      padding-left: 18px;
      border-left: 1px solid #333;
  
      .btn {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 20px;
        padding: 0;
        margin: 0 2px;
      }
    }
  
    .i-in2 {
      border-top: 1px solid #eee;
      text-align: center;
      padding: 10px 0;
    }
  
    .autor-honeti {
      opacity: 0.7;
  
      &:hover {
        opacity: 1;
      }
  
      img {
        vertical-align: middle;
        position: relative;
        top: -1px;
      }
    }
  }
  
  @media all and (max-width: 1201px) {
    .footer {
      .i-menu {
        width: 100%;
        text-align: center;
        padding: 10px 0;
      }
  
      .i-social {
        width: 100%;
        text-align: center;
        padding: 0 0 10px 0;
        margin: 0;
        border: none;
      }
    }
  }
  
  @media all and (max-width: 991px) {
    .section-padding {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  
    .header {
      padding-right: 42px;
  
      .i-logo {
        height: 40px;
        width: 81px;
      }
  
      .i-in2 {
        position: initial;
        height: 60px;
        padding-left: 0;
      }
    }
  
    .open-menu .bx-search {
      position: fixed;
      z-index: 99999;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 10px;
      background: #fff;
      border-top: 1px solid #eee;
  
      &.active .i-in1 {
        width: 100%;
      }
    }
  
    .bx-search {
      padding-left: 0;
      margin-left: 0;
      border: none;
    }
  
    .bx-products-filer .item2 .i-desc .i-btn {
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      bottom: 0;
    }
  
    .bx-accordion {
      margin-bottom: 15px;
  
      .i-accordion-opener {
        font-size: 20px;
  
        i {
          font-size: 26px;
          margin-left: 5px;
        }
      }
  
      .bx-accordion-in {
        padding-left: 10px;
  
        &:last-child {
          margin-bottom: 5px;
        }
  
        .i-accordion-opener {
          font-size: 18px;
  
          i {
            font-size: 22px;
          }
        }
      }
    }
  
    .bx-inspirations-filter {
      margin: 0 -5px;
  
      .item {
        padding: 5px;
      }
  
      .item-desc .i-btn {
        min-width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 18px;
      }
    }
  
    .arrow-see-inspirations, #cookies-message-container {
      display: none;
    }
  }
  
  @media all and (max-width: 767px) {
    .bx-search {
      width: 100%;
      display: flex;
      align-items: flex-end;
  
      &.active .i-in1 {
        width: calc(100% - 40px);
      }
    }
  
    .bx-product {
      padding: 20px;
  
      .i-img {
        height: 200px;
        line-height: 200px;
      }
    }
  
    .bx-products-filter-checkbox .form-field {
      padding: 5px 10px;
    }
  
    .bx-inspirations-filter {
      .item {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
  
      .item-desc {
        padding: 20px 20px 5px 20px;
      }
    }
  
    .bx-recipe-details .i-main-photo .i-ico-group {
      right: 10px;
      top: 10px;
    }
  
    .footer {
      margin-top: 40px;
  
      .i-in1 {
        padding: 20px 0;
      }
    }
  }
  
  @media all and (max-width: 575px) {
    .ico-go-top {
      width: 35px;
      height: 35px;
      right: 10px;
      bottom: 20px;
  
      &::after {
        width: 10px;
        height: 10px;
        margin: -3px 0 0 -5px;
      }
    }
  
    .bx-download {
      flex-wrap: wrap;
      padding: 10px 15px;
  
      .i-ico {
        display: none;
      }
  
      .i-in2 {
        width: 100%;
        margin-top: 5px;
      }
  
      .i-btn {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
      }
    }
  }
  
  @media all and (max-width: 479px) {
    .bx-inspirations-filter .item {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {}

  input[name=uploads] {
    display: none;
  }