

.ts-2 {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
  }
  
  .wrap {
    overflow: hidden;
  }
  
  .fw-300, .fw-light {
    font-weight: 300 !important;
  }
  
  .fw-600 {
    font-weight: 600 !important;
  }
  
  .fw-400, .fw-normal {
    font-weight: normal !important;
  }
  
  .fw-500 {
    font-weight: 500 !important;
  }
  
  .fw-700, .fw-bold {
    font-weight: bold !important;
  }
  
  .fw-800 {
    font-weight: 800 !important;
  }
  
  .fw-900 {
    font-weight: 900 !important;
  }
  
  .tt-uppercase {
    text-transform: uppercase !important;
  }
  
  .tt-lowercase {
    text-transform: lowercase !important;
  }
  
  .tt-none {
    text-transform: none !important;
  }
  
  .fs-italic {
    font-style: italic !important;
  }
  
  .ls-none {
    letter-spacing: normal !important;
  }
  
  .ls-1 {
    letter-spacing: 1px;
  }
  
  .ls-2 {
    letter-spacing: 2px;
  }
  
  .ls-4 {
    letter-spacing: 4px;
  }
  
  .ls-6 {
    letter-spacing: 6px;
  }
  
  .ff-lobster {
    font-family: 'Lobster', cursive;
    font-weight: normal;
  }
  
  .clear {
    clear: both !important;
  }
  
  .m-center {
    margin: 0 auto;
    float: none !important;
  }
  
  .full {
    width: 100% !important;
  }
  
  .bx-rel {
    position: relative;
  }
  
  .no-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .fixed {
    position: fixed;
  }
  
  .vh100 {
    min-height: 100vh;
  }
  
  .full-height {
    min-height: calc(100vh - 0px);
  }
  
  .fade {
    transition: opacity .15s linear;
  }
  
  .table-wrapper {
    overflow-y: auto;
    width: 100%;
  }
  
  .table-fixed {
    table-layout: fixed;
  }
  
  .table-hover tbody tr {
    cursor: pointer;
  
    &:hover td {
      background: #f4fbff;
    }
  }
  
  .cr-poitner, [data-target], [data-src], [onclick] {
    cursor: pointer !important;
  }
  
  .cr-default {
    cursor: default !important;
  }
  
  .ico-link {
    opacity: 1;
  
    &:hover {
      opacity: 0.7;
    }
  }
  
  .obj-fit-cover {
    object-fit: cover;
    height: 100%;
  }
  
  [class*='transform-center'] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  
  .transform-center-x {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .transform-center-y {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  
  .img-max-auto img {
    max-width: 100% !important;
    height: auto !important;
  }
  
  .color-green {
    color: #197b30 !important;
  }
  
  .color-blue {
    color: $blue !important;
  }
  
  .color-navy-blue {
    color: #081f6e !important;
  }
  
  .color-white {
    color: #fff !important;
  }
  
  .color-gray {
    color: #c8c8c8 !important;
  }
  
  .color-red {
    color: #e52528 !important;
  }
  
  .color-normal {
    color: #333 !important;
  }
  
  .color-black {
    color: #000 !important;
  }
  
  a[class*='color-'] {
    &:hover, &:focus {
      opacity: 0.8;
    }
  }
  
  [data-target][class*='color-']:hover, [data-src][class*='color-']:hover {
    opacity: 0.8;
  }
  
  .bg-white {
    background-color: #fff !important;
  }
  
  .bg-black {
    background-color: #000 !important;
  }
  
  .bg-gray {
    background-color: #f1f1f1 !important;
  }
  
  .bx-scroll-y {
    overflow: hidden;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch;
    position: relative;
  
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: #999;
      -webkit-border-radius: 4px;
      outline: 2px solid #f1f1f1;
      outline-offset: -2px;
      border: 2px solid #f1f1f1;
    }
  
    &::-webkit-scrollbar-track-piece {
      background-color: #f1f1f1;
      -webkit-border-radius: 0;
    }
  }
  
  .alert {
    padding: 10px;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    text-align: center;
  
    &.fixed {
      position: fixed;
      z-index: 99999;
      top: 10px;
      left: 50%;
      padding: 7px 20px;
      transform: translateX(-50%);
      min-width: 250px;
    }
  
    p a {
      text-decoration: underline;
  
      &:hover {
        text-decoration: none;
      }
    }
  }
  
  .alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  
    a {
      color: #3c763d !important;
    }
  }
  
  .alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
    overflow: hidden;
  
    a {
      color: #31708f !important;
    }
  }
  
  .alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
  
    a {
      color: #8a6d3b !important;
    }
  }
  
  .alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  
    a {
      color: #a94442 !important;
    }
  }
  
  .fl {
    float: left !important;
  }
  
  .fr {
    float: right !important;
  }
  
  .t-center, .text-center {
    text-align: center !important;
  }
  
  .t-right, .text-right {
    text-align: right !important;
  }
  
  .t-left, .text-left {
    text-align: left !important;
  }
  
  .t-justify, .text-justify {
    text-align: justify !important;
  }
  
  .m-0, .nom {
    margin: 0 !important;
  }
  
  .m-1 {
    margin: 1px !important;
  }
  
  .m-2 {
    margin: 2px !important;
  }
  
  .m-5 {
    margin: 5px !important;
  }
  
  .m-10 {
    margin: 10px !important;
  }
  
  .m-15 {
    margin: 15px !important;
  }
  
  .m-20 {
    margin: 20px !important;
  }
  
  .m-30 {
    margin: 30px !important;
  }
  
  .m-40 {
    margin: 40px !important;
  }
  
  .m-50 {
    margin: 50px !important;
  }
  
  .m-60 {
    margin: 60px !important;
  }
  
  .m-70 {
    margin: 70px !important;
  }
  
  .mt-0, .nom-t {
    margin-top: 0 !important;
  }
  
  .mt-1 {
    margin-top: 1px !important;
  }
  
  .mt-2 {
    margin-top: 2px !important;
  }
  
  .mt-5 {
    margin-top: 5px !important;
  }
  
  .mt-10 {
    margin-top: 10px !important;
  }
  
  .mt-15 {
    margin-top: 15px !important;
  }
  
  .mt-20 {
    margin-top: 20px !important;
  }
  
  .mt-30 {
    margin-top: 30px !important;
  }
  
  .mt-40 {
    margin-top: 40px !important;
  }
  
  .mt-50 {
    margin-top: 50px !important;
  }
  
  .mt-60 {
    margin-top: 60px !important;
  }
  
  .mt-70 {
    margin-top: 70px !important;
  }
  
  .mr-0, .nom-r {
    margin-right: 0 !important;
  }
  
  .mr-1 {
    margin-right: 1px !important;
  }
  
  .mr-2 {
    margin-right: 2px !important;
  }
  
  .mr-5 {
    margin-right: 5px !important;
  }
  
  .mr-10 {
    margin-right: 10px !important;
  }
  
  .mr-15 {
    margin-right: 15px !important;
  }
  
  .mr-20 {
    margin-right: 20px !important;
  }
  
  .mr-30 {
    margin-right: 30px !important;
  }
  
  .mr-40 {
    margin-right: 40px !important;
  }
  
  .mr-50 {
    margin-right: 50px !important;
  }
  
  .mr-60 {
    margin-right: 60px !important;
  }
  
  .mr-70 {
    margin-right: 70px !important;
  }
  
  .ml-0, .nom-l {
    margin-left: 0 !important;
  }
  
  .ml-1 {
    margin-left: 1px !important;
  }
  
  .ml-2 {
    margin-left: 2px !important;
  }
  
  .ml-5 {
    margin-left: 5px !important;
  }
  
  .ml-10 {
    margin-left: 10px !important;
  }
  
  .ml-15 {
    margin-left: 15px !important;
  }
  
  .ml-20 {
    margin-left: 20px !important;
  }
  
  .ml-30 {
    margin-left: 30px !important;
  }
  
  .ml-40 {
    margin-left: 40px !important;
  }
  
  .ml-50 {
    margin-left: 50px !important;
  }
  
  .ml-60 {
    margin-left: 60px !important;
  }
  
  .ml-70 {
    margin-left: 70px !important;
  }
  
  .mb-0, .nom-b {
    margin-bottom: 0 !important;
  }
  
  .mb-1 {
    margin-bottom: 1px !important;
  }
  
  .mb-2 {
    margin-bottom: 2px !important;
  }
  
  .mb-5 {
    margin-bottom: 5px !important;
  }
  
  .mb-10 {
    margin-bottom: 10px !important;
  }
  
  .mb-15 {
    margin-bottom: 15px !important;
  }
  
  .mb-20 {
    margin-bottom: 20px !important;
  }
  
  .mb-30 {
    margin-bottom: 30px !important;
  }
  
  .mb-40 {
    margin-bottom: 40px !important;
  }
  
  .mb-50 {
    margin-bottom: 50px !important;
  }
  
  .mb-60 {
    margin-bottom: 60px !important;
  }
  
  .mb-70 {
    margin-bottom: 70px !important;
  }
  
  .p-0, .nop {
    padding: 0 !important;
  }
  
  .p-5 {
    padding: 5px !important;
  }
  
  .p-10 {
    padding: 10px !important;
  }
  
  .p-15 {
    padding: 15px !important;
  }
  
  .p-20 {
    padding: 20px !important;
  }
  
  .p-30 {
    padding: 30px !important;
  }
  
  .p-40 {
    padding: 40px !important;
  }
  
  .p-50 {
    padding: 50px !important;
  }
  
  .p-60 {
    padding: 60px !important;
  }
  
  .pt-0, .nop-t {
    padding-top: 0 !important;
  }
  
  .pt-5 {
    padding-top: 5px !important;
  }
  
  .pt-10 {
    padding-top: 10px !important;
  }
  
  .pt-15 {
    padding-top: 15px !important;
  }
  
  .pt-20 {
    padding-top: 20px !important;
  }
  
  .pt-30 {
    padding-top: 30px !important;
  }
  
  .pt-40 {
    padding-top: 40px !important;
  }
  
  .pt-50 {
    padding-top: 50px !important;
  }
  
  .pt-60 {
    padding-top: 60px !important;
  }
  
  .pr-0, .nop-r {
    padding-right: 0 !important;
  }
  
  .pr-5 {
    padding-right: 5px !important;
  }
  
  .pr-10 {
    padding-right: 10px !important;
  }
  
  .pr-15 {
    padding-right: 15px !important;
  }
  
  .pr-20 {
    padding-right: 20px !important;
  }
  
  .pr-30 {
    padding-right: 30px !important;
  }
  
  .pr-40 {
    padding-right: 40px !important;
  }
  
  .pr-50 {
    padding-right: 50px !important;
  }
  
  .pr-60 {
    padding-right: 60px !important;
  }
  
  .pl-0, .nop-l {
    padding-left: 0 !important;
  }
  
  .pl-5 {
    padding-left: 5px !important;
  }
  
  .pl-10 {
    padding-left: 10px !important;
  }
  
  .pl-15 {
    padding-left: 15px !important;
  }
  
  .pl-20 {
    padding-left: 20px !important;
  }
  
  .pl-30 {
    padding-left: 30px !important;
  }
  
  .pl-40 {
    padding-left: 40px !important;
  }
  
  .pl-50 {
    padding-left: 50px !important;
  }
  
  .pl-60 {
    padding-left: 60px !important;
  }
  
  .pb-0, .nop-b {
    padding-bottom: 0 !important;
  }
  
  .pb-5 {
    padding-bottom: 5px !important;
  }
  
  .pb-10 {
    padding-bottom: 10px !important;
  }
  
  .pb-15 {
    padding-bottom: 15px !important;
  }
  
  .pb-20 {
    padding-bottom: 20px !important;
  }
  
  .pb-30 {
    padding-bottom: 30px !important;
  }
  
  .pb-40 {
    padding-bottom: 40px !important;
  }
  
  .pb-50 {
    padding-bottom: 50px !important;
  }
  
  .pb-60 {
    padding-bottom: 60px !important;
  }
  
  .fs-10 {
    font-size: 10px !important;
    line-height: 18px !important;
  }
  
  .fs-11 {
    font-size: 11px !important;
    line-height: 19px !important;
  }
  
  .fs-12 {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  
  .fs-13 {
    font-size: 13px !important;
    line-height: 21px !important;
  }
  
  .fs-14 {
    font-size: 14px !important;
    line-height: 22px !important;
  }
  
  .fs-15 {
    font-size: 15px !important;
    line-height: 23px !important;
  }
  
  .fs-16 {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  
  .fs-18 {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  
  .fs-20 {
    font-size: 20px !important;
    line-height: 32px !important;
  }
  
  .fs-22 {
    font-size: 22px !important;
    line-height: 34px !important;
  }
  
  .fs-24 {
    font-size: 24px !important;
    line-height: 36px !important;
  }
  
  .fs-26 {
    font-size: 26px !important;
    line-height: 38px !important;
  }
  
  .fs-28 {
    font-size: 28px !important;
    line-height: 40px !important;
  }
  
  .fs-30 {
    font-size: 30px !important;
    line-height: 42px !important;
  }
  
  .fs-36 {
    font-size: 36px !important;
    line-height: 36px !important;
  }
  
  .fs-60 {
    font-size: 60px !important;
    line-height: 48px !important;
  }
  
  .fs-80 {
    font-size: 80px !important;
    line-height: 68px !important;
  }
  
  .fs-120 {
    font-size: 120px !important;
    line-height: 100px !important;
  }
  
  .br-gray {
    border: 1px solid #c8c8c8 !important;
  }
  
  .brt-gray {
    border-top: 1px solid #c8c8c8 !important;
  }
  
  .brr-gray {
    border-right: 1px solid #c8c8c8 !important;
  }
  
  .brb-gray {
    border-bottom: 1px solid #c8c8c8 !important;
  }
  
  .brl-gray {
    border-left: 1px solid #c8c8c8 !important;
  }
  
  .br-white {
    border: 1px solid #fff !important;
  }
  
  .brt-white {
    border-top: 1px solid #fff !important;
  }
  
  .brr-white {
    border-right: 1px solid #fff !important;
  }
  
  .brb-white {
    border-bottom: 1px solid #fff !important;
  }
  
  .brl-white {
    border-left: 1px solid #fff !important;
  }
  
  .br-none {
    border: none !important;
  }
  
  .brt-none {
    border-top: none !important;
  }
  
  .brr-none {
    border-right: none !important;
  }
  
  .brb-none {
    border-bottom: none !important;
  }
  
  .brl-none {
    border-left: none !important;
  }
  
  @media (min-width: 576px) {
    .fl-sm {
      float: left !important;
    }
  
    .fr-sm {
      float: right !important;
    }
  
    .t-sm-center, .text-sm-center {
      text-align: center !important;
    }
  
    .t-sm-right, .text-sm-right {
      text-align: right !important;
    }
  
    .t-sm-left, .text-sm-left {
      text-align: left !important;
    }
  
    .t-sm-justify, .text-sm-justify {
      text-align: justify !important;
    }
  
    .m-sm-0, .nom-sm {
      margin: 0 !important;
    }
  
    .m-sm-1 {
      margin: 1px !important;
    }
  
    .m-sm-2 {
      margin: 2px !important;
    }
  
    .m-sm-5 {
      margin: 5px !important;
    }
  
    .m-sm-10 {
      margin: 10px !important;
    }
  
    .m-sm-15 {
      margin: 15px !important;
    }
  
    .m-sm-20 {
      margin: 20px !important;
    }
  
    .m-sm-30 {
      margin: 30px !important;
    }
  
    .m-sm-40 {
      margin: 40px !important;
    }
  
    .m-sm-50 {
      margin: 50px !important;
    }
  
    .m-sm-60 {
      margin: 60px !important;
    }
  
    .m-sm-70 {
      margin: 70px !important;
    }
  
    .mt-sm-0 {
      margin-top: 0 !important;
    }
  
    .mt-sm-1 {
      margin-top: 1px !important;
    }
  
    .mt-sm-2 {
      margin-top: 2px !important;
    }
  
    .mt-sm-5 {
      margin-top: 5px !important;
    }
  
    .mt-sm-10 {
      margin-top: 10px !important;
    }
  
    .mt-sm-15 {
      margin-top: 15px !important;
    }
  
    .mt-sm-20 {
      margin-top: 20px !important;
    }
  
    .mt-sm-30 {
      margin-top: 30px !important;
    }
  
    .mt-sm-40 {
      margin-top: 40px !important;
    }
  
    .mt-sm-50 {
      margin-top: 50px !important;
    }
  
    .mt-sm-60 {
      margin-top: 60px !important;
    }
  
    .mt-sm-70 {
      margin-top: 70px !important;
    }
  
    .mr-sm-0 {
      margin-right: 0 !important;
    }
  
    .mr-sm-1 {
      margin-right: 1px !important;
    }
  
    .mr-sm-2 {
      margin-right: 2px !important;
    }
  
    .mr-sm-5 {
      margin-right: 5px !important;
    }
  
    .mr-sm-10 {
      margin-right: 10px !important;
    }
  
    .mr-sm-15 {
      margin-right: 15px !important;
    }
  
    .mr-sm-20 {
      margin-right: 20px !important;
    }
  
    .mr-sm-30 {
      margin-right: 30px !important;
    }
  
    .mr-sm-40 {
      margin-right: 40px !important;
    }
  
    .mr-sm-50 {
      margin-right: 50px !important;
    }
  
    .mr-sm-60 {
      margin-right: 60px !important;
    }
  
    .mr-sm-70 {
      margin-right: 70px !important;
    }
  
    .ml-sm-0 {
      margin-left: 0 !important;
    }
  
    .ml-sm-1 {
      margin-left: 1px !important;
    }
  
    .ml-sm-2 {
      margin-left: 2px !important;
    }
  
    .ml-sm-5 {
      margin-left: 5px !important;
    }
  
    .ml-sm-10 {
      margin-left: 10px !important;
    }
  
    .ml-sm-15 {
      margin-left: 15px !important;
    }
  
    .ml-sm-20 {
      margin-left: 20px !important;
    }
  
    .ml-sm-30 {
      margin-left: 30px !important;
    }
  
    .ml-sm-40 {
      margin-left: 40px !important;
    }
  
    .ml-sm-50 {
      margin-left: 50px !important;
    }
  
    .ml-sm-60 {
      margin-left: 60px !important;
    }
  
    .ml-sm-70 {
      margin-left: 70px !important;
    }
  
    .mb-sm-0 {
      margin-bottom: 0 !important;
    }
  
    .mb-sm-1 {
      margin-bottom: 1px !important;
    }
  
    .mb-sm-2 {
      margin-bottom: 2px !important;
    }
  
    .mb-sm-5 {
      margin-bottom: 5px !important;
    }
  
    .mb-sm-10 {
      margin-bottom: 10px !important;
    }
  
    .mb-sm-15 {
      margin-bottom: 15px !important;
    }
  
    .mb-sm-20 {
      margin-bottom: 20px !important;
    }
  
    .mb-sm-30 {
      margin-bottom: 30px !important;
    }
  
    .mb-sm-40 {
      margin-bottom: 40px !important;
    }
  
    .mb-sm-50 {
      margin-bottom: 50px !important;
    }
  
    .mb-sm-60 {
      margin-bottom: 60px !important;
    }
  
    .mb-sm-70 {
      margin-bottom: 70px !important;
    }
  
    .p-sm-0 {
      padding: 0 !important;
    }
  
    .p-sm-5 {
      padding: 5px !important;
    }
  
    .p-sm-10 {
      padding: 10px !important;
    }
  
    .p-sm-15 {
      padding: 15px !important;
    }
  
    .p-sm-20 {
      padding: 20px !important;
    }
  
    .p-sm-30 {
      padding: 30px !important;
    }
  
    .p-sm-40 {
      padding: 40px !important;
    }
  
    .p-sm-50 {
      padding: 50px !important;
    }
  
    .p-sm-60 {
      padding: 60px !important;
    }
  
    .pt-sm-0 {
      padding-top: 0 !important;
    }
  
    .pt-sm-5 {
      padding-top: 5px !important;
    }
  
    .pt-sm-10 {
      padding-top: 10px !important;
    }
  
    .pt-sm-15 {
      padding-top: 15px !important;
    }
  
    .pt-sm-20 {
      padding-top: 20px !important;
    }
  
    .pt-sm-30 {
      padding-top: 30px !important;
    }
  
    .pt-sm-40 {
      padding-top: 40px !important;
    }
  
    .pt-sm-50 {
      padding-top: 50px !important;
    }
  
    .pt-sm-60 {
      padding-top: 60px !important;
    }
  
    .pr-sm-0 {
      padding-right: 0 !important;
    }
  
    .pr-sm-5 {
      padding-right: 5px !important;
    }
  
    .pr-sm-10 {
      padding-right: 10px !important;
    }
  
    .pr-sm-15 {
      padding-right: 15px !important;
    }
  
    .pr-sm-20 {
      padding-right: 20px !important;
    }
  
    .pr-sm-30 {
      padding-right: 30px !important;
    }
  
    .pr-sm-40 {
      padding-right: 40px !important;
    }
  
    .pr-sm-50 {
      padding-right: 50px !important;
    }
  
    .pr-sm-60 {
      padding-right: 60px !important;
    }
  
    .pl-sm-0 {
      padding-left: 0 !important;
    }
  
    .pl-sm-5 {
      padding-left: 5px !important;
    }
  
    .pl-sm-10 {
      padding-left: 10px !important;
    }
  
    .pl-sm-15 {
      padding-left: 15px !important;
    }
  
    .pl-sm-20 {
      padding-left: 20px !important;
    }
  
    .pl-sm-30 {
      padding-left: 30px !important;
    }
  
    .pl-sm-40 {
      padding-left: 40px !important;
    }
  
    .pl-sm-50 {
      padding-left: 50px !important;
    }
  
    .pl-sm-60 {
      padding-left: 60px !important;
    }
  
    .pb-sm-0 {
      padding-bottom: 0 !important;
    }
  
    .pb-sm-5 {
      padding-bottom: 5px !important;
    }
  
    .pb-sm-10 {
      padding-bottom: 10px !important;
    }
  
    .pb-sm-15 {
      padding-bottom: 15px !important;
    }
  
    .pb-sm-20 {
      padding-bottom: 20px !important;
    }
  
    .pb-sm-30 {
      padding-bottom: 30px !important;
    }
  
    .pb-sm-40 {
      padding-bottom: 40px !important;
    }
  
    .pb-sm-50 {
      padding-bottom: 50px !important;
    }
  
    .pb-sm-60 {
      padding-bottom: 60px !important;
    }
  
    .fs-sm-10 {
      font-size: 10px !important;
      line-height: 18px !important;
    }
  
    .fs-sm-11 {
      font-size: 11px !important;
      line-height: 19px !important;
    }
  
    .fs-sm-12 {
      font-size: 12px !important;
      line-height: 20px !important;
    }
  
    .fs-sm-13 {
      font-size: 13px !important;
      line-height: 21px !important;
    }
  
    .fs-sm-14 {
      font-size: 14px !important;
      line-height: 22px !important;
    }
  
    .fs-sm-15 {
      font-size: 15px !important;
      line-height: 23px !important;
    }
  
    .fs-sm-16 {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  
    .fs-sm-18 {
      font-size: 18px !important;
      line-height: 30px !important;
    }
  
    .fs-sm-20 {
      font-size: 20px !important;
      line-height: 32px !important;
    }
  
    .fs-sm-22 {
      font-size: 22px !important;
      line-height: 34px !important;
    }
  
    .fs-sm-24 {
      font-size: 24px !important;
      line-height: 36px !important;
    }
  
    .fs-sm-26 {
      font-size: 26px !important;
      line-height: 38px !important;
    }
  
    .fs-sm-28 {
      font-size: 28px !important;
      line-height: 40px !important;
    }
  
    .fs-sm-30 {
      font-size: 30px !important;
      line-height: 42px !important;
    }
  
    .fs-sm-36 {
      font-size: 36px !important;
      line-height: 36px !important;
    }
  
    .fs-sm-60 {
      font-size: 60px !important;
      line-height: 48px !important;
    }
  
    .fs-sm-80 {
      font-size: 80px !important;
      line-height: 68px !important;
    }
  
    .fs-sm-120 {
      font-size: 120px !important;
      line-height: 100px !important;
    }
  
    .br-sm-gray {
      border: 1px solid #c8c8c8 !important;
    }
  
    .brt-sm-gray {
      border-top: 1px solid #c8c8c8 !important;
    }
  
    .brr-sm-gray {
      border-right: 1px solid #c8c8c8 !important;
    }
  
    .brb-sm-gray {
      border-bottom: 1px solid #c8c8c8 !important;
    }
  
    .brl-sm-gray {
      border-left: 1px solid #c8c8c8 !important;
    }
  
    .br-sm-white {
      border: 1px solid #fff !important;
    }
  
    .brt-sm-white {
      border-top: 1px solid #fff !important;
    }
  
    .brr-sm-white {
      border-right: 1px solid #fff !important;
    }
  
    .brb-sm-white {
      border-bottom: 1px solid #fff !important;
    }
  
    .brl-sm-white {
      border-left: 1px solid #fff !important;
    }
  
    .br-sm-none {
      border: none !important;
    }
  
    .brt-sm-none {
      border-top: none !important;
    }
  
    .brr-sm-none {
      border-right: none !important;
    }
  
    .brb-sm-none {
      border-bottom: none !important;
    }
  
    .brl-sm-none {
      border-left: none !important;
    }
  }
  
  @media (min-width: 768px) {
    .fl-md {
      float: left !important;
    }
  
    .fr-md {
      float: right !important;
    }
  
    .t-md-center, .text-md-center {
      text-align: center !important;
    }
  
    .t-md-right, .text-md-right {
      text-align: right !important;
    }
  
    .t-md-left, .text-md-left {
      text-align: left !important;
    }
  
    .t-md-justify, .text-md-justify {
      text-align: justify !important;
    }
  
    .m-md-0, .nom-md {
      margin: 0 !important;
    }
  
    .m-md-1 {
      margin: 1px !important;
    }
  
    .m-md-2 {
      margin: 2px !important;
    }
  
    .m-md-5 {
      margin: 5px !important;
    }
  
    .m-md-10 {
      margin: 10px !important;
    }
  
    .m-md-15 {
      margin: 15px !important;
    }
  
    .m-md-20 {
      margin: 20px !important;
    }
  
    .m-md-30 {
      margin: 30px !important;
    }
  
    .m-md-40 {
      margin: 40px !important;
    }
  
    .m-md-50 {
      margin: 50px !important;
    }
  
    .m-md-60 {
      margin: 60px !important;
    }
  
    .m-md-70 {
      margin: 70px !important;
    }
  
    .mt-md-0 {
      margin-top: 0 !important;
    }
  
    .mt-md-1 {
      margin-top: 1px !important;
    }
  
    .mt-md-2 {
      margin-top: 2px !important;
    }
  
    .mt-md-5 {
      margin-top: 5px !important;
    }
  
    .mt-md-10 {
      margin-top: 10px !important;
    }
  
    .mt-md-15 {
      margin-top: 15px !important;
    }
  
    .mt-md-20 {
      margin-top: 20px !important;
    }
  
    .mt-md-30 {
      margin-top: 30px !important;
    }
  
    .mt-md-40 {
      margin-top: 40px !important;
    }
  
    .mt-md-50 {
      margin-top: 50px !important;
    }
  
    .mt-md-60 {
      margin-top: 60px !important;
    }
  
    .mt-md-70 {
      margin-top: 70px !important;
    }
  
    .mr-md-0 {
      margin-right: 0 !important;
    }
  
    .mr-md-1 {
      margin-right: 1px !important;
    }
  
    .mr-md-2 {
      margin-right: 2px !important;
    }
  
    .mr-md-5 {
      margin-right: 5px !important;
    }
  
    .mr-md-10 {
      margin-right: 10px !important;
    }
  
    .mr-md-15 {
      margin-right: 15px !important;
    }
  
    .mr-md-20 {
      margin-right: 20px !important;
    }
  
    .mr-md-30 {
      margin-right: 30px !important;
    }
  
    .mr-md-40 {
      margin-right: 40px !important;
    }
  
    .mr-md-50 {
      margin-right: 50px !important;
    }
  
    .mr-md-60 {
      margin-right: 60px !important;
    }
  
    .mr-md-70 {
      margin-right: 70px !important;
    }
  
    .ml-md-0 {
      margin-left: 0 !important;
    }
  
    .ml-md-1 {
      margin-left: 1px !important;
    }
  
    .ml-md-2 {
      margin-left: 2px !important;
    }
  
    .ml-md-5 {
      margin-left: 5px !important;
    }
  
    .ml-md-10 {
      margin-left: 10px !important;
    }
  
    .ml-md-15 {
      margin-left: 15px !important;
    }
  
    .ml-md-20 {
      margin-left: 20px !important;
    }
  
    .ml-md-30 {
      margin-left: 30px !important;
    }
  
    .ml-md-40 {
      margin-left: 40px !important;
    }
  
    .ml-md-50 {
      margin-left: 50px !important;
    }
  
    .ml-md-60 {
      margin-left: 60px !important;
    }
  
    .ml-md-70 {
      margin-left: 70px !important;
    }
  
    .mb-md-0 {
      margin-bottom: 0 !important;
    }
  
    .mb-md-1 {
      margin-bottom: 1px !important;
    }
  
    .mb-md-2 {
      margin-bottom: 2px !important;
    }
  
    .mb-md-5 {
      margin-bottom: 5px !important;
    }
  
    .mb-md-10 {
      margin-bottom: 10px !important;
    }
  
    .mb-md-15 {
      margin-bottom: 15px !important;
    }
  
    .mb-md-20 {
      margin-bottom: 20px !important;
    }
  
    .mb-md-30 {
      margin-bottom: 30px !important;
    }
  
    .mb-md-40 {
      margin-bottom: 40px !important;
    }
  
    .mb-md-50 {
      margin-bottom: 50px !important;
    }
  
    .mb-md-60 {
      margin-bottom: 60px !important;
    }
  
    .mb-md-70 {
      margin-bottom: 70px !important;
    }
  
    .p-md-0, .nop-md {
      padding: 0 !important;
    }
  
    .p-md-5 {
      padding: 5px !important;
    }
  
    .p-md-10 {
      padding: 10px !important;
    }
  
    .p-md-15 {
      padding: 15px !important;
    }
  
    .p-md-20 {
      padding: 20px !important;
    }
  
    .p-md-30 {
      padding: 30px !important;
    }
  
    .p-md-40 {
      padding: 40px !important;
    }
  
    .p-md-50 {
      padding: 50px !important;
    }
  
    .p-md-60 {
      padding: 60px !important;
    }
  
    .p-md-100 {
      padding: 100px !important;
    }
  
    .pt-md-0 {
      padding-top: 0 !important;
    }
  
    .pt-md-5 {
      padding-top: 5px !important;
    }
  
    .pt-md-10 {
      padding-top: 10px !important;
    }
  
    .pt-md-15 {
      padding-top: 15px !important;
    }
  
    .pt-md-20 {
      padding-top: 20px !important;
    }
  
    .pt-md-30 {
      padding-top: 30px !important;
    }
  
    .pt-md-40 {
      padding-top: 40px !important;
    }
  
    .pt-md-50 {
      padding-top: 50px !important;
    }
  
    .pt-md-60 {
      padding-top: 60px !important;
    }
  
    .pt-md-100 {
      padding-top: 100px !important;
    }
  
    .pr-md-0 {
      padding-right: 0 !important;
    }
  
    .pr-md-5 {
      padding-right: 5px !important;
    }
  
    .pr-md-10 {
      padding-right: 10px !important;
    }
  
    .pr-md-15 {
      padding-right: 15px !important;
    }
  
    .pr-md-20 {
      padding-right: 20px !important;
    }
  
    .pr-md-30 {
      padding-right: 30px !important;
    }
  
    .pr-md-40 {
      padding-right: 40px !important;
    }
  
    .pr-md-50 {
      padding-right: 50px !important;
    }
  
    .pr-md-60 {
      padding-right: 60px !important;
    }
  
    .pr-md-100 {
      padding-right: 100px !important;
    }
  
    .pl-md-0 {
      padding-left: 0 !important;
    }
  
    .pl-md-5 {
      padding-left: 5px !important;
    }
  
    .pl-md-10 {
      padding-left: 10px !important;
    }
  
    .pl-md-15 {
      padding-left: 15px !important;
    }
  
    .pl-md-20 {
      padding-left: 20px !important;
    }
  
    .pl-md-30 {
      padding-left: 30px !important;
    }
  
    .pl-md-40 {
      padding-left: 40px !important;
    }
  
    .pl-md-50 {
      padding-left: 50px !important;
    }
  
    .pl-md-60 {
      padding-left: 60px !important;
    }
  
    .pl-md-100 {
      padding-left: 100px !important;
    }
  
    .pb-md-0 {
      padding-bottom: 0 !important;
    }
  
    .pb-md-5 {
      padding-bottom: 5px !important;
    }
  
    .pb-md-10 {
      padding-bottom: 10px !important;
    }
  
    .pb-md-15 {
      padding-bottom: 15px !important;
    }
  
    .pb-md-20 {
      padding-bottom: 20px !important;
    }
  
    .pb-md-30 {
      padding-bottom: 30px !important;
    }
  
    .pb-md-40 {
      padding-bottom: 40px !important;
    }
  
    .pb-md-50 {
      padding-bottom: 50px !important;
    }
  
    .pb-md-60 {
      padding-bottom: 60px !important;
    }
  
    .pb-md-100 {
      padding-bottom: 100px !important;
    }
  
    .fs-md-10 {
      font-size: 10px !important;
      line-height: 18px !important;
    }
  
    .fs-md-11 {
      font-size: 11px !important;
      line-height: 19px !important;
    }
  
    .fs-md-12 {
      font-size: 12px !important;
      line-height: 20px !important;
    }
  
    .fs-md-13 {
      font-size: 13px !important;
      line-height: 21px !important;
    }
  
    .fs-md-14 {
      font-size: 14px !important;
      line-height: 22px !important;
    }
  
    .fs-md-15 {
      font-size: 15px !important;
      line-height: 23px !important;
    }
  
    .fs-md-16 {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  
    .fs-md-18 {
      font-size: 18px !important;
      line-height: 30px !important;
    }
  
    .fs-md-20 {
      font-size: 20px !important;
      line-height: 32px !important;
    }
  
    .fs-md-22 {
      font-size: 22px !important;
      line-height: 34px !important;
    }
  
    .fs-md-24 {
      font-size: 24px !important;
      line-height: 36px !important;
    }
  
    .fs-md-26 {
      font-size: 26px !important;
      line-height: 38px !important;
    }
  
    .fs-md-28 {
      font-size: 28px !important;
      line-height: 40px !important;
    }
  
    .fs-md-30 {
      font-size: 30px !important;
      line-height: 42px !important;
    }
  
    .fs-md-36 {
      font-size: 36px !important;
      line-height: 36px !important;
    }
  
    .fs-md-60 {
      font-size: 60px !important;
      line-height: 48px !important;
    }
  
    .fs-md-80 {
      font-size: 80px !important;
      line-height: 68px !important;
    }
  
    .fs-md-120 {
      font-size: 120px !important;
      line-height: 100px !important;
    }
  
    .br-md-gray {
      border: 1px solid #c8c8c8 !important;
    }
  
    .brt-md-gray {
      border-top: 1px solid #c8c8c8 !important;
    }
  
    .brr-md-gray {
      border-right: 1px solid #c8c8c8 !important;
    }
  
    .brb-md-gray {
      border-bottom: 1px solid #c8c8c8 !important;
    }
  
    .brl-md-gray {
      border-left: 1px solid #c8c8c8 !important;
    }
  
    .br-md-white {
      border: 1px solid #fff !important;
    }
  
    .brt-md-white {
      border-top: 1px solid #fff !important;
    }
  
    .brr-md-white {
      border-right: 1px solid #fff !important;
    }
  
    .brb-md-white {
      border-bottom: 1px solid #fff !important;
    }
  
    .brl-md-white {
      border-left: 1px solid #fff !important;
    }
  
    .br-md-none {
      border: none !important;
    }
  
    .brt-md-none {
      border-top: none !important;
    }
  
    .brr-md-none {
      border-right: none !important;
    }
  
    .brb-md-none {
      border-bottom: none !important;
    }
  
    .brl-md-none {
      border-left: none !important;
    }
  }
  
  @media (min-width: 992px) {
    .fl-lg {
      float: left !important;
    }
  
    .fr-lg {
      float: right !important;
    }
  
    .t-lg-center, .text-lg-center {
      text-align: center !important;
    }
  
    .t-lg-right, .text-lg-right {
      text-align: right !important;
    }
  
    .t-lg-left, .text-lg-left {
      text-align: left !important;
    }
  
    .t-lg-justify, .text-lg-justify {
      text-align: justify !important;
    }
  
    .m-lg-0, .nom-lg {
      margin: 0 !important;
    }
  
    .m-lg-1 {
      margin: 1px !important;
    }
  
    .m-lg-2 {
      margin: 2px !important;
    }
  
    .m-lg-5 {
      margin: 5px !important;
    }
  
    .m-lg-10 {
      margin: 10px !important;
    }
  
    .m-lg-15 {
      margin: 15px !important;
    }
  
    .m-lg-20 {
      margin: 20px !important;
    }
  
    .m-lg-30 {
      margin: 30px !important;
    }
  
    .m-lg-40 {
      margin: 40px !important;
    }
  
    .m-lg-50 {
      margin: 50px !important;
    }
  
    .m-lg-60 {
      margin: 60px !important;
    }
  
    .m-lg-70 {
      margin: 70px !important;
    }
  
    .mt-lg-0 {
      margin-top: 0 !important;
    }
  
    .mt-lg-1 {
      margin-top: 1px !important;
    }
  
    .mt-lg-2 {
      margin-top: 2px !important;
    }
  
    .mt-lg-5 {
      margin-top: 5px !important;
    }
  
    .mt-lg-10 {
      margin-top: 10px !important;
    }
  
    .mt-lg-15 {
      margin-top: 15px !important;
    }
  
    .mt-lg-20 {
      margin-top: 20px !important;
    }
  
    .mt-lg-30 {
      margin-top: 30px !important;
    }
  
    .mt-lg-40 {
      margin-top: 40px !important;
    }
  
    .mt-lg-50 {
      margin-top: 50px !important;
    }
  
    .mt-lg-60 {
      margin-top: 60px !important;
    }
  
    .mt-lg-70 {
      margin-top: 70px !important;
    }
  
    .mr-lg-0 {
      margin-right: 0 !important;
    }
  
    .mr-lg-1 {
      margin-right: 1px !important;
    }
  
    .mr-lg-2 {
      margin-right: 2px !important;
    }
  
    .mr-lg-5 {
      margin-right: 5px !important;
    }
  
    .mr-lg-10 {
      margin-right: 10px !important;
    }
  
    .mr-lg-15 {
      margin-right: 15px !important;
    }
  
    .mr-lg-20 {
      margin-right: 20px !important;
    }
  
    .mr-lg-30 {
      margin-right: 30px !important;
    }
  
    .mr-lg-40 {
      margin-right: 40px !important;
    }
  
    .mr-lg-50 {
      margin-right: 50px !important;
    }
  
    .mr-lg-60 {
      margin-right: 60px !important;
    }
  
    .mr-lg-70 {
      margin-right: 70px !important;
    }
  
    .ml-lg-0 {
      margin-left: 0 !important;
    }
  
    .ml-lg-1 {
      margin-left: 1px !important;
    }
  
    .ml-lg-2 {
      margin-left: 2px !important;
    }
  
    .ml-lg-5 {
      margin-left: 5px !important;
    }
  
    .ml-lg-10 {
      margin-left: 10px !important;
    }
  
    .ml-lg-15 {
      margin-left: 15px !important;
    }
  
    .ml-lg-20 {
      margin-left: 20px !important;
    }
  
    .ml-lg-30 {
      margin-left: 30px !important;
    }
  
    .ml-lg-40 {
      margin-left: 40px !important;
    }
  
    .ml-lg-50 {
      margin-left: 50px !important;
    }
  
    .ml-lg-60 {
      margin-left: 60px !important;
    }
  
    .ml-lg-70 {
      margin-left: 70px !important;
    }
  
    .mb-lg-0 {
      margin-bottom: 0 !important;
    }
  
    .mb-lg-1 {
      margin-bottom: 1px !important;
    }
  
    .mb-lg-2 {
      margin-bottom: 2px !important;
    }
  
    .mb-lg-5 {
      margin-bottom: 5px !important;
    }
  
    .mb-lg-10 {
      margin-bottom: 10px !important;
    }
  
    .mb-lg-15 {
      margin-bottom: 15px !important;
    }
  
    .mb-lg-20 {
      margin-bottom: 20px !important;
    }
  
    .mb-lg-30 {
      margin-bottom: 30px !important;
    }
  
    .mb-lg-40 {
      margin-bottom: 40px !important;
    }
  
    .mb-lg-50 {
      margin-bottom: 50px !important;
    }
  
    .mb-lg-60 {
      margin-bottom: 60px !important;
    }
  
    .mb-lg-70 {
      margin-bottom: 70px !important;
    }
  
    .p-lg-0, .nop-lg {
      padding: 0 !important;
    }
  
    .p-lg-5, .p-lg-10 {
      padding: 10px !important;
    }
  
    .p-lg-15 {
      padding: 15px !important;
    }
  
    .p-lg-20 {
      padding: 20px !important;
    }
  
    .p-lg-30 {
      padding: 30px !important;
    }
  
    .p-lg-40 {
      padding: 40px !important;
    }
  
    .p-lg-50 {
      padding: 50px !important;
    }
  
    .p-lg-60 {
      padding: 60px !important;
    }
  
    .p-lg-100 {
      padding: 100px !important;
    }
  
    .pt-lg-0 {
      padding-top: 0 !important;
    }
  
    .pt-lg-5 {
      padding-top: 5px !important;
    }
  
    .pt-lg-10 {
      padding-top: 10px !important;
    }
  
    .pt-lg-15 {
      padding-top: 15px !important;
    }
  
    .pt-lg-20 {
      padding-top: 20px !important;
    }
  
    .pt-lg-30 {
      padding-top: 30px !important;
    }
  
    .pt-lg-40 {
      padding-top: 40px !important;
    }
  
    .pt-lg-50 {
      padding-top: 50px !important;
    }
  
    .pt-lg-60 {
      padding-top: 60px !important;
    }
  
    .pt-lg-100 {
      padding-top: 100px !important;
    }
  
    .pr-lg-0 {
      padding-right: 0 !important;
    }
  
    .pr-lg-5 {
      padding-right: 5px !important;
    }
  
    .pr-lg-10 {
      padding-right: 10px !important;
    }
  
    .pr-lg-15 {
      padding-right: 15px !important;
    }
  
    .pr-lg-20 {
      padding-right: 20px !important;
    }
  
    .pr-lg-30 {
      padding-right: 30px !important;
    }
  
    .pr-lg-40 {
      padding-right: 40px !important;
    }
  
    .pr-lg-50 {
      padding-right: 50px !important;
    }
  
    .pr-lg-60 {
      padding-right: 60px !important;
    }
  
    .pr-lg-100 {
      padding-right: 100px !important;
    }
  
    .pl-lg-0 {
      padding-left: 0 !important;
    }
  
    .pl-lg-5 {
      padding-left: 5px !important;
    }
  
    .pl-lg-10 {
      padding-left: 10px !important;
    }
  
    .pl-lg-15 {
      padding-left: 15px !important;
    }
  
    .pl-lg-20 {
      padding-left: 20px !important;
    }
  
    .pl-lg-30 {
      padding-left: 30px !important;
    }
  
    .pl-lg-40 {
      padding-left: 40px !important;
    }
  
    .pl-lg-50 {
      padding-left: 50px !important;
    }
  
    .pl-lg-60 {
      padding-left: 60px !important;
    }
  
    .pl-lg-100 {
      padding-left: 100px !important;
    }
  
    .pb-lg-0 {
      padding-bottom: 0 !important;
    }
  
    .pb-lg-5 {
      padding-bottom: 5px !important;
    }
  
    .pb-lg-10 {
      padding-bottom: 10px !important;
    }
  
    .pb-lg-15 {
      padding-bottom: 15px !important;
    }
  
    .pb-lg-20 {
      padding-bottom: 20px !important;
    }
  
    .pb-lg-30 {
      padding-bottom: 30px !important;
    }
  
    .pb-lg-40 {
      padding-bottom: 40px !important;
    }
  
    .pb-lg-50 {
      padding-bottom: 50px !important;
    }
  
    .pb-lg-60 {
      padding-bottom: 60px !important;
    }
  
    .pb-lg-100 {
      padding-bottom: 100px !important;
    }
  
    .fs-lg-10 {
      font-size: 10px !important;
      line-height: 18px !important;
    }
  
    .fs-lg-11 {
      font-size: 11px !important;
      line-height: 19px !important;
    }
  
    .fs-lg-12 {
      font-size: 12px !important;
      line-height: 20px !important;
    }
  
    .fs-lg-13 {
      font-size: 13px !important;
      line-height: 21px !important;
    }
  
    .fs-lg-14 {
      font-size: 14px !important;
      line-height: 22px !important;
    }
  
    .fs-lg-15 {
      font-size: 15px !important;
      line-height: 23px !important;
    }
  
    .fs-lg-16 {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  
    .fs-lg-18 {
      font-size: 18px !important;
      line-height: 30px !important;
    }
  
    .fs-lg-20 {
      font-size: 20px !important;
      line-height: 32px !important;
    }
  
    .fs-lg-22 {
      font-size: 22px !important;
      line-height: 34px !important;
    }
  
    .fs-lg-24 {
      font-size: 24px !important;
      line-height: 36px !important;
    }
  
    .fs-lg-26 {
      font-size: 26px !important;
      line-height: 38px !important;
    }
  
    .fs-lg-28 {
      font-size: 28px !important;
      line-height: 40px !important;
    }
  
    .fs-lg-30 {
      font-size: 30px !important;
      line-height: 42px !important;
    }
  
    .fs-lg-36 {
      font-size: 36px !important;
      line-height: 36px !important;
    }
  
    .fs-lg-60 {
      font-size: 60px !important;
      line-height: 48px !important;
    }
  
    .fs-lg-80 {
      font-size: 80px !important;
      line-height: 68px !important;
    }
  
    .fs-lg-120 {
      font-size: 120px !important;
      line-height: 100px !important;
    }
  
    .br-lg-gray {
      border: 1px solid #c8c8c8 !important;
    }
  
    .brt-lg-gray {
      border-top: 1px solid #c8c8c8 !important;
    }
  
    .brr-lg-gray {
      border-right: 1px solid #c8c8c8 !important;
    }
  
    .brb-lg-gray {
      border-bottom: 1px solid #c8c8c8 !important;
    }
  
    .brl-lg-gray {
      border-left: 1px solid #c8c8c8 !important;
    }
  
    .br-lg-white {
      border: 1px solid #fff !important;
    }
  
    .brt-lg-white {
      border-top: 1px solid #fff !important;
    }
  
    .brr-lg-white {
      border-right: 1px solid #fff !important;
    }
  
    .brb-lg-white {
      border-bottom: 1px solid #fff !important;
    }
  
    .brl-lg-white {
      border-left: 1px solid #fff !important;
    }
  
    .br-lg-none {
      border: none !important;
    }
  
    .brt-lg-none {
      border-top: none !important;
    }
  
    .brr-lg-none {
      border-right: none !important;
    }
  
    .brb-lg-none {
      border-bottom: none !important;
    }
  
    .brl-lg-none {
      border-left: none !important;
    }
  }
  
  @media (min-width: 1200px) {
    .fl-xl {
      float: left !important;
    }
  
    .fr-xl {
      float: right !important;
    }
  
    .t-xl-center {
      text-align: center !important;
    }
  
    .t-xl-right {
      text-align: right !important;
    }
  
    .t-xl-left {
      text-align: left !important;
    }
  
    .t-xl-justify {
      text-align: justify !important;
    }
  
    .m-xl-0, .nom-xl {
      margin: 0 !important;
    }
  
    .m-xl-1 {
      margin: 1px !important;
    }
  
    .m-xl-2 {
      margin: 2px !important;
    }
  
    .m-xl-5 {
      margin: 5px !important;
    }
  
    .m-xl-10 {
      margin: 10px !important;
    }
  
    .m-xl-15 {
      margin: 15px !important;
    }
  
    .m-xl-20 {
      margin: 20px !important;
    }
  
    .m-xl-30 {
      margin: 30px !important;
    }
  
    .m-xl-40 {
      margin: 40px !important;
    }
  
    .m-xl-50 {
      margin: 50px !important;
    }
  
    .m-xl-60 {
      margin: 60px !important;
    }
  
    .m-xl-70 {
      margin: 70px !important;
    }
  
    .mt-xl-0 {
      margin-top: 0 !important;
    }
  
    .mt-xl-5 {
      margin-top: 5px !important;
    }
  
    .mt-xl-1 {
      margin-top: 1px !important;
    }
  
    .mt-xl-2 {
      margin-top: 2px !important;
    }
  
    .mt-xl-10 {
      margin-top: 10px !important;
    }
  
    .mt-xl-15 {
      margin-top: 15px !important;
    }
  
    .mt-xl-20 {
      margin-top: 20px !important;
    }
  
    .mt-xl-30 {
      margin-top: 30px !important;
    }
  
    .mt-xl-40 {
      margin-top: 40px !important;
    }
  
    .mt-xl-50 {
      margin-top: 50px !important;
    }
  
    .mt-xl-60 {
      margin-top: 60px !important;
    }
  
    .mt-xl-70 {
      margin-top: 70px !important;
    }
  
    .mr-xl-0 {
      margin-right: 0 !important;
    }
  
    .mr-xl-5 {
      margin-right: 5px !important;
    }
  
    .mr-xl-1 {
      margin-right: 1px !important;
    }
  
    .mr-xl-2 {
      margin-right: 2px !important;
    }
  
    .mr-xl-10 {
      margin-right: 10px !important;
    }
  
    .mr-xl-15 {
      margin-right: 15px !important;
    }
  
    .mr-xl-20 {
      margin-right: 20px !important;
    }
  
    .mr-xl-30 {
      margin-right: 30px !important;
    }
  
    .mr-xl-40 {
      margin-right: 40px !important;
    }
  
    .mr-xl-50 {
      margin-right: 50px !important;
    }
  
    .mr-xl-60 {
      margin-right: 60px !important;
    }
  
    .mr-xl-70 {
      margin-right: 70px !important;
    }
  
    .ml-xl-0 {
      margin-left: 0 !important;
    }
  
    .ml-xl-1 {
      margin-left: 1px !important;
    }
  
    .ml-xl-2 {
      margin-left: 2px !important;
    }
  
    .ml-xl-5 {
      margin-left: 5px !important;
    }
  
    .ml-xl-10 {
      margin-left: 10px !important;
    }
  
    .ml-xl-15 {
      margin-left: 15px !important;
    }
  
    .ml-xl-20 {
      margin-left: 20px !important;
    }
  
    .ml-xl-30 {
      margin-left: 30px !important;
    }
  
    .ml-xl-40 {
      margin-left: 40px !important;
    }
  
    .ml-xl-50 {
      margin-left: 50px !important;
    }
  
    .ml-xl-60 {
      margin-left: 60px !important;
    }
  
    .ml-xl-70 {
      margin-left: 70px !important;
    }
  
    .mb-xl-0 {
      margin-bottom: 0 !important;
    }
  
    .mb-xl-1 {
      margin-bottom: 1px !important;
    }
  
    .mb-xl-2 {
      margin-bottom: 2px !important;
    }
  
    .mb-xl-5 {
      margin-bottom: 5px !important;
    }
  
    .mb-xl-10 {
      margin-bottom: 10px !important;
    }
  
    .mb-xl-15 {
      margin-bottom: 15px !important;
    }
  
    .mb-xl-20 {
      margin-bottom: 20px !important;
    }
  
    .mb-xl-30 {
      margin-bottom: 30px !important;
    }
  
    .mb-xl-40 {
      margin-bottom: 40px !important;
    }
  
    .mb-xl-50 {
      margin-bottom: 50px !important;
    }
  
    .mb-xl-60 {
      margin-bottom: 60px !important;
    }
  
    .mb-xl-70 {
      margin-bottom: 70px !important;
    }
  
    .p-xl-0, .nop-xl {
      padding: 0 !important;
    }
  
    .p-xl-5 {
      padding: 5px !important;
    }
  
    .p-xl-10 {
      padding: 10px !important;
    }
  
    .p-xl-15 {
      padding: 15px !important;
    }
  
    .p-xl-20 {
      padding: 20px !important;
    }
  
    .p-xl-30 {
      padding: 30px !important;
    }
  
    .p-xl-40 {
      padding: 40px !important;
    }
  
    .p-xl-50 {
      padding: 50px !important;
    }
  
    .p-xl-60 {
      padding: 60px !important;
    }
  
    .pt-xl-0 {
      padding-top: 0 !important;
    }
  
    .pt-xl-5 {
      padding-top: 5px !important;
    }
  
    .pt-xl-10 {
      padding-top: 10px !important;
    }
  
    .pt-xl-15 {
      padding-top: 15px !important;
    }
  
    .pt-xl-20 {
      padding-top: 20px !important;
    }
  
    .pt-xl-30 {
      padding-top: 30px !important;
    }
  
    .pt-xl-40 {
      padding-top: 40px !important;
    }
  
    .pt-xl-50 {
      padding-top: 50px !important;
    }
  
    .pt-xl-60 {
      padding-top: 60px !important;
    }
  
    .pr-xl-0 {
      padding-right: 0 !important;
    }
  
    .pr-xl-5 {
      padding-right: 5px !important;
    }
  
    .pr-xl-10 {
      padding-right: 10px !important;
    }
  
    .pr-xl-15 {
      padding-right: 15px !important;
    }
  
    .pr-xl-20 {
      padding-right: 20px !important;
    }
  
    .pr-xl-30 {
      padding-right: 30px !important;
    }
  
    .pr-xl-40 {
      padding-right: 40px !important;
    }
  
    .pr-xl-50 {
      padding-right: 50px !important;
    }
  
    .pr-xl-60 {
      padding-right: 60px !important;
    }
  
    .pl-xl-0 {
      padding-left: 0 !important;
    }
  
    .pl-xl-5 {
      padding-left: 5px !important;
    }
  
    .pl-xl-10 {
      padding-left: 10px !important;
    }
  
    .pl-xl-15 {
      padding-left: 15px !important;
    }
  
    .pl-xl-20 {
      padding-left: 20px !important;
    }
  
    .pl-xl-30 {
      padding-left: 30px !important;
    }
  
    .pl-xl-40 {
      padding-left: 40px !important;
    }
  
    .pl-xl-50 {
      padding-left: 50px !important;
    }
  
    .pl-xl-60 {
      padding-left: 60px !important;
    }
  
    .pb-xl-0 {
      padding-left: 0 !important;
    }
  
    .pb-xl-5 {
      padding-left: 5px !important;
    }
  
    .pb-xl-10 {
      padding-left: 10px !important;
    }
  
    .pb-xl-15 {
      padding-left: 15px !important;
    }
  
    .pb-xl-20 {
      padding-left: 20px !important;
    }
  
    .pb-xl-30 {
      padding-left: 30px !important;
    }
  
    .pb-xl-40 {
      padding-left: 40px !important;
    }
  
    .pb-xl-50 {
      padding-left: 50px !important;
    }
  
    .pb-xl-60 {
      padding-left: 60px !important;
    }
  
    .fs-xl-10 {
      font-size: 10px !important;
      line-height: 18px !important;
    }
  
    .fs-xl-11 {
      font-size: 11px !important;
      line-height: 19px !important;
    }
  
    .fs-xl-12 {
      font-size: 12px !important;
      line-height: 20px !important;
    }
  
    .fs-xl-13 {
      font-size: 13px !important;
      line-height: 21px !important;
    }
  
    .fs-xl-14 {
      font-size: 14px !important;
      line-height: 22px !important;
    }
  
    .fs-xl-15 {
      font-size: 15px !important;
      line-height: 23px !important;
    }
  
    .fs-xl-16 {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  
    .fs-xl-18 {
      font-size: 18px !important;
      line-height: 30px !important;
    }
  
    .fs-xl-20 {
      font-size: 20px !important;
      line-height: 32px !important;
    }
  
    .fs-xl-22 {
      font-size: 22px !important;
      line-height: 34px !important;
    }
  
    .fs-xl-24 {
      font-size: 24px !important;
      line-height: 36px !important;
    }
  
    .fs-xl-26 {
      font-size: 26px !important;
      line-height: 38px !important;
    }
  
    .fs-xl-28 {
      font-size: 28px !important;
      line-height: 40px !important;
    }
  
    .fs-xl-30 {
      font-size: 30px !important;
      line-height: 42px !important;
    }
  
    .fs-xl-36 {
      font-size: 36px !important;
      line-height: 36px !important;
    }
  
    .fs-xl-60 {
      font-size: 60px !important;
      line-height: 48px !important;
    }
  
    .fs-xl-80 {
      font-size: 80px !important;
      line-height: 68px !important;
    }
  
    .fs-xl-120 {
      font-size: 120px !important;
      line-height: 100px !important;
    }
  
    .br-xl-gray {
      border: 1px solid #c8c8c8 !important;
    }
  
    .brt-xl-gray {
      border-top: 1px solid #c8c8c8 !important;
    }
  
    .brr-xl-gray {
      border-right: 1px solid #c8c8c8 !important;
    }
  
    .brb-xl-gray {
      border-bottom: 1px solid #c8c8c8 !important;
    }
  
    .brl-xl-gray {
      border-left: 1px solid #c8c8c8 !important;
    }
  
    .br-xl-white {
      border: 1px solid #fff !important;
    }
  
    .brt-xl-white {
      border-top: 1px solid #fff !important;
    }
  
    .brr-xl-white {
      border-right: 1px solid #fff !important;
    }
  
    .brb-xl-white {
      border-bottom: 1px solid #fff !important;
    }
  
    .brl-xl-white {
      border-left: 1px solid #fff !important;
    }
  
    .br-xl-none {
      border: none !important;
    }
  
    .brt-xl-none {
      border-top: none !important;
    }
  
    .brr-xl-none {
      border-right: none !important;
    }
  
    .brb-xl-none {
      border-bottom: none !important;
    }
  
    .brl-xl-none {
      border-left: none !important;
    }
  }
  
  .lh-normal {
    line-height: normal !important;
  }
  
  @media print {
    .d-print-none {
      display: none !important;
    }
  
    .d-print-inline {
      display: inline !important;
    }
  
    .d-print-inline-block {
      display: inline-block !important;
    }
  
    .d-print-block {
      display: block !important;
    }
  
    .d-print-table {
      display: table !important;
    }
  
    .d-print-table-row {
      display: table-row !important;
    }
  
    .d-print-table-cell {
      display: table-cell !important;
    }
  
    .d-print-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
  
    .d-print-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }